export const LN = {
    KO: 'ko',
    EN: 'en'
};

export const KO = {
    FORM: {
        TITLE: {
            HEAD: '',
            TAIL: '로그인'
        },
        NAME: '이름',
        EMAIL: '이메일',
        PLACEHOLDER: {
            NAME: '이름',
            EMAIL: '이메일'
        },
        VALIDATION: {
            NAME: {
                REQUIRED: '이름을 입력하세요'  
            },
            EMAIL: {
                REQUIRED: '이메일을 입력하세요' ,
                INVALID: '이메일이 유효하지 않습니다' 
            }
        },
        BTN: {
            CONTINUE: '다음',
            SENDING: '전송중...',
            SIGNUP: '회원가입',
            SIGNIN: '로그인',
            RETRY: '재시도',
            CLOSE: '닫기'
        },
        MSG: {
            N1: '아직 회원이 아니신가요?',
            N2: '이미 회원이신가요?'
        }
    },
    INFO: {
        LOGINLINK: '이메일에서 로그인 링크를 확인하세요!',
        SPAMCHECK: '3분 이내에 메일이 오지 않으면, 스팸 메일을 확인하세요!',
        BACKTOLOGIN: '로그인으로 돌아가기'
    }
};

export const EN = {
    FORM: {
        TITLE: {
            HEAD: 'Log in to',
            TAIL: ''
        },
        NAME: 'Name',
        EMAIL: 'Email',
        PLACEHOLDER: {
            NAME: 'name',
            EMAIL: 'email'
        },
        VALIDATION: {
            NAME: {
                REQUIRED: 'Enter your name'  
            },
            EMAIL: {
                REQUIRED: 'Enter your email address' ,
                INVALID: 'Invalid email address' 
            }
        },
        BTN: {
            CONTINUE: 'Continue',
            SENDING: 'Sending...',
            SIGNUP: 'Sign up',
            SIGNIN: 'Sign in',
            RETRY: 'Retry',
            CLOSE: 'Close'
        },
        MSG: {
            N1: 'Don&#39;t have an account?',
            N2: 'Already a member?'
        },
        INFO: {
            LOGINLINK: 'We&#39;ve sent you a login link!',
            SPAMCHECK: 'If the email doesn&#39;t arrive in 3 minutes, be sure to check your spam folder!',
            BACKTOLOGIN: 'Back to Log in'
        }
    }
};

export const CURRENT_LANGUAGE = LN.KO;

export function lang() {
    switch (CURRENT_LANGUAGE) {
    case LN.KO:
    default:
        return KO;
    }
}
